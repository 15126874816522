import { useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import {
  Box, Button, Grid, TextField, MenuItem, TableContainer, Table,
  TableHead, TableBody, TableRow, TableCell, Typography, TableSortLabel,
  SvgIcon, InputAdornment, Switch, Autocomplete, Divider, Snackbar, Alert, useMediaQuery, IconButton, FormControlLabel, Tooltip,
} from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import { MagicWand01, Plus, SearchSm } from '../../../assets';
import { OrderKind } from '../../../enums/OrderKind';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import { isAutoShipOrder, OrderType as OrderTypeEnum, OrderTypeTranslationMap } from '../../../enums/OrderType';
import {
  client, Category, OrderType,
  ItemsFilterOptions, Item, AddedItem, DeliverySchedule, OrderDetails, Supplier,
} from '../../../app/services/api/orderManagementClient';
import { OrderStatus } from '../../../enums/OrderStatus';
import CustomPagination from '../../../components/forms/Pagination';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import dayjs, { Dayjs } from 'dayjs';
import PageTitle from '../../../components/shared/PageTitle';
import PageArea from '../../../components/shared/PageArea';
import BackButton from '../../../components/shared/actions/BackButton';
import { useTranslation } from 'react-i18next';
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized';
import { isOrderCategoryParent, OrdersCategoriesParentsMap, isOrderCategory, OrderCategoriesTranslationMap } from '../../../enums/OrderCategories';
import { podClient, PredictedDemandModel } from './../../../app/services/api/podClient';
import useNswagClient from './../../../hooks/api/useNswagClient';
import './QuickOrder.css';
import { QuantityWarningDialog } from './../../../components/shared/orders/QuantityWarningDialog';

const QuickOrderPage = () => {
  const navigate = useNavigate();
  const { selectedStore } = useContext(UserContext);
  const { t } = useTranslation('quickOrder');
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [productSearch, setProductSearch] = useState('');
  const [orderType, setOrderType] = useState<number>(-1);
  const [supplier, setSupplier] = useState<string>('');
  const [category, setCategory] = useState<number>(-1);
  const [deliveryDate, setDeliveryDate] = useState<Dayjs | null>(null);
  const [cutOffDate, setCutOffDate] = useState<Dayjs | null>(null);
  const [orderKind, setOrderKind] = useState(OrderKind.Forecast);

  const [page, setPage] = useState<number>(0);
  const pageReset = useRef(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('Name');
  const [totalPages, setTotalPages] = useState<number>(0);

  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [deliverySchedules, setDeliverySchedules] = useState<DeliverySchedule[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const [addedItems, setAddedItems] = useState<AddedItem[]>([]);
  const [itemQuantities, setItemQuantities] = useState<Record<number, number>>({});
  const { getConversions } = useNswagClient();
  const [isPodEnabled, setIsPodEnabled] = useState(false);
  const [showPredictedQuantity, setShowPredictedQuantity] = useState<boolean>(true);
  const [isMatchPredictedQuantitiesActive, setIsMatchPredictedQuantitiesActive] = useState<boolean>(false);
  const [matchedPredictionPages, setMatchedPredictionPages] = useState<number[]>([]);
  const [predictedDemands, setPredictedDemands] = useState<PredictedDemandModel[]>([]);
  const [loadedPredictions, setLoadedPredictions] = useState<Set<string>>(new Set());

  const [showQuantityWarningDialog, setShowQuantityWarningDialog] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  useEffect(() => {
    const fetchOrderTypesOnPageLoad = async () => {
      setLoading(true);
      await fetchOrderTypes();
      setLoading(false);
    };

    if (id && !isNaN(parseInt(id))) {
      loadData()
        .catch((error) => {
          handleSnackbarOpen(t('errorLoadingData' + ' ' + error), 'error');
        });
    }

    fetchOrderTypesOnPageLoad()
      .catch((error) => {
        handleSnackbarOpen(t('errorLoadingData') + ' ' + error, 'error');
      });
  }, [selectedStore, id]);

  useEffect(() => {
    loadPodStoreConfiguration();
  }, [selectedStore]);

  useEffect(() => {
    const fetchItemsOnPagination = async () => {
      if (!loading && orderType !== -1 && !pageReset.current) {
        setLoading(true);
        setLoadedPredictions(new Set());
        await fetchItems({
          item: productSearch,
          orderType: orderType,
          storeNumber: selectedStore?.storeNumber,
          categoryId: category,
          supplier: supplier,
          pageNumber: page,
          pageSize: pageSize,
          sort: orderBy,
          direction: order,
        });
        setLoading(false);
      }
      pageReset.current = false;
    };
    fetchItemsOnPagination()
      .catch((error) => {
        handleSnackbarOpen(t('errorLoadingData') + ' ' + error, 'error');
      });
  }, [page]);

  useEffect(() => {
    if (isPodEnabled && deliveryDate && items.length > 0) {
      loadPredictedDemands();
    }
  }, [isPodEnabled, deliveryDate, items]);

  useEffect(() => {
    const loadedItems = Array.from(loadedPredictions);
    const areItemsLoaded = addedItems.map(i => i.item.sku).every(x => loadedItems.includes(x));
    if (!areItemsLoaded && isPodEnabled && deliveryDate && !loading) {
      loadPredictedDemands();
    }
  }, [addedItems]);

  useEffect(() => {
    if (!matchedPredictionPages.includes(page) && isMatchPredictedQuantitiesActive) {
      setIsMatchPredictedQuantitiesActive(true);
      setMatchedPredictionPages([...matchedPredictionPages, page]);
      matchPredictedQuantities();
    }
  }, [predictedDemands]);

  const loadData = async () => {
    setLoading(true);
    await fetchOrderDetails();
    setLoading(false);
  };

  const updateOrderEditStatus = (order: OrderDetails) => {
    if (isAutoShipOrder(order.orderTypeId)) {
      return OrderStatus.Autoshipment;
    }

    return order.status;
  };

  const fetchOrderDetails = async () => {
    try {
      const result = await client.getOrder(selectedStore?.storeNumber, parseInt(id ?? ''));
      result.data.status = updateOrderEditStatus(result.data);
      setOrderDetails(result.data);
      setDeliveryDate(dayjs(result.data.deliveryDate, 'DD-MM-YYYY'));
      setCutOffDate(dayjs(result.data.cutOffDate, 'DD-MM-YYYY'));
      setOrderKind(result.data.emergencyOrder ? OrderKind.Emergency : OrderKind.Forecast);
      setSupplier(result.data.supplierNumber);
      const fetchedAddedItems = result.data.items.map(item => ({
        item: {
          id: item.itemId,
          sku: item.sku,
          name: item.name,
          categoryId: item.categoryId,
          category: item.category,
          stockOnHand: item.stockOnHand,
          stockOnOrder: item.stockOnOrder,
          price: item.price,
          primaryUom: item.primaryUom,
          orderableUom: item.orderableUom,
          barcode: '',
          barcodeUom: '',
          imageId: item.imageId,
          thumbnailUrl: item.thumbnailUrl,
          quantity: item.quantity,
          packSize: item.packSize,
        },
        quantity: item.quantity,
        isNew: false,
        maxQuantity: item.maxQuantity,
        minQuantity: item.minQuantity,
      }));

      setAddedItems(fetchedAddedItems);

      const newItemQuantities = result.data.items.reduce((acc, item) => ({
        ...acc,
        [item.itemId]: item.quantity,
      }), {});

      setItemQuantities(newItemQuantities);
      await fetchDeliverySchedules(result.data.orderTypeId, result.data.supplierNumber);
    } catch (error) {
      handleSnackbarOpen('Error fetching order details! ' + error, 'error');
    }
  };

  const fetchItems = async (filters: ItemsFilterOptions) => {
    try {
      const itemsResponse = await client.getItems(filters);
      setItems(itemsResponse.data.items);
      setTotalPages(itemsResponse.data.totalPages);
      if (page !== itemsResponse.data.pageNumber) {
        pageReset.current = true;
        setPage(itemsResponse.data.pageNumber);
      }
    } catch (error) {
      handleSnackbarOpen(t('errorLoadingData') + ' ' + error, 'error');
    }
  };

  const fetchOrderTypes = async () => {
    try {
      const orderTypesResponse = await client.getOrderTypes(selectedStore?.storeNumber);
      setOrderTypes(orderTypesResponse.data);
      if (orderTypesResponse.success && orderTypesResponse.data.length > 0) {
        const defaultOrderType = orderTypesResponse.success && orderTypesResponse.data.length > 0 ? orderTypesResponse.data[0].id : -1;
        setOrderType(defaultOrderType);
        await fetchSuppliers();
        await fetchDeliverySchedules(defaultOrderType, '');
        await fetchCategories(defaultOrderType, '');
        await fetchItems({
          item: '',
          orderType: defaultOrderType,
          storeNumber: selectedStore?.storeNumber,
          categoryId: -1,
          supplier: '',
          pageNumber: page,
          pageSize: pageSize,
          sort: 'Name',
          direction: 'asc',
        });
      }
    } catch (error) {
      handleSnackbarOpen(t('errorLoadingData') + ' ' + error, 'error');
    }
  };

  const fetchCategories = async (selectedOrderType: number, supplier: string) => {
    try {
      const categories = await client.getCategories(selectedStore?.storeNumber, selectedOrderType, supplier);
      setCategories(categories.data);
    } catch (error) {
      setCategories([]);
      handleSnackbarOpen(t('errorLoadingData') + ' ' + error, 'error');
    }
  };

  const fetchDeliverySchedules = async (selectedOrderType: number, supplierNumber: string) => {
    try {
      const deliverySchedulesResponse = await client.getDeliverySchedules(selectedStore?.storeNumber, selectedOrderType, supplierNumber);
      setDeliverySchedules(deliverySchedulesResponse.data || []);
    } catch (error) {
      handleSnackbarOpen(t('errorLoadingData') + ' ' + error, 'error');
    }
  };

  const fetchSuppliers = async () => {
    try {
      const suppliers = await client.getSuppliers(selectedStore?.storeNumber);
      setSuppliers(suppliers.data);
    } catch (error) {
      setSuppliers([]);
      handleSnackbarOpen(t('errorLoadingData') + ' ' + error, 'error');
    }
  };

  const loadPodStoreConfiguration = async () => {
    try {
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const response = await podClient.getStoreConfiguration(selectedStore.franchiseName, selectedStore.storeNumber);
        setIsPodEnabled(response.isPodEnabled);
      }
    } catch (error) {
      handleSnackbarOpen(t('errorLoadingData'), 'error');
    }
  };

  const loadPredictedDemands = async () => {
    setLoading(true);
    try {
      const parsedDate = dayjs(deliveryDate, 'DD-MM-YYYY');
      const dates = [parsedDate.format('DD-MM-YYYY')];
      const nextDate = deliverySchedules.find(x => parsedDate.isBefore(dayjs(x.deliveryDate, 'DD-MM-YYYY')));

      if (nextDate) {
        dates.push(nextDate.deliveryDate);
      }
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const addedItemNumbers = addedItems.map(x => x.item.sku);

        const podItemNumbers = Array.from(new Set([...addedItemNumbers, ...items.map(x => x.sku)]));

        setLoadedPredictions((prev) => {
          const existingPredictions = Array.from(prev);
          return new Set([...existingPredictions, ...podItemNumbers]);
        });
        
        const predictions = await podClient.getPredictionsForItems(selectedStore.franchiseName, selectedStore.storeNumber, dates,  podItemNumbers);
        await loadUomConversions(predictions);
      }
    } catch (error) {
      handleSnackbarOpen(t('errorLoadingData'), 'error');
    } finally {
      setLoading(false);
    }
  };

  const loadUomConversions = async (predictions: PredictedDemandModel[]) => {
    const itemNumbers = predictions.map(x => x.itemNumber);
    const allItems = Array.from(new Set([...items, ...addedItems.map(x => x.item)]));
    const availableItems = allItems.filter(x => itemNumbers.includes(x.sku));
    const response = await getConversions(undefined, undefined, undefined, undefined, selectedStore?.storeNumber);

    const conversions = response.conversions?.filter(conversion => {
      const item = availableItems?.find(i => i.sku === conversion?.itemId);
      return item && item.primaryUom === conversion?.fromUom && item.orderableUom === conversion.toUom;
    });

    predictions.forEach((x) => {
      const conversion = conversions?.find(c => c?.itemId === x.itemNumber);
      const conversionNumber = conversion?.conversionNumber ?? 1;

      x.predictedDemandItems = x.predictedDemandItems.map(({ quantity, ...rest }) => ({
        ...rest,
        quantity: Math.ceil(quantity / conversionNumber),
      }));
    });

    setPredictedDemands(predictions);
  };

  const handleSort = async (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setLoading(true);
    await fetchItems({
      storeNumber: selectedStore?.storeNumber,
      orderType: orderType,
      categoryId: category,
      item: productSearch,
      supplier: supplier,
      pageNumber: page,
      pageSize: pageSize,
      sort: property,
      direction: isAsc ? 'desc' : 'asc',
    });
    setLoading(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    await fetchItems(getFilterOptions(true));
    await fetchCategories(orderType, supplier);
    setLoading(false);
  };

  const handleSave = async () => {
    if (!selectedStore?.storeNumber || !deliveryDate || !cutOffDate || orderType === -1) {
      return;
    }

    if (!orderDetails && orderKind === OrderKind.Emergency) {
      const createOrderRequest = {
        storeNumber: selectedStore.storeNumber,
        emergencyOrder: orderKind === OrderKind.Emergency,
        orderTypeId: orderType,
        deliveryDate: deliveryDate.format('YYYY-MM-DD'),
        cutOffDate: cutOffDate.format('YYYY-MM-DD'),
        supplierNumber: supplier,
        items: addedItems.map(product => ({
          id: product.item.id,
          sku: product.item.sku,
          quantity: itemQuantities[product.item.id] || product.quantity || 1,
          predictedQuantity: predictedDemands.find(x => x.itemNumber === product.item.sku)?.predictedDemandItems[0].quantity,
        })),
      };

      try {
        setLoading(true);
        const response = await client.createOrder(createOrderRequest);
        setLoading(false);
        if (response.success) {
          handleSnackbarOpen(t('orderCreatedSuccessfully'), 'success');
        }
        else {
          setShowQuantityWarningDialog(true);
          setWarningMessage(response.message);
          fixQuantities();
        }
      } catch (error) {
        setLoading(false);
      }
    }
    else if (orderDetails && orderKind === OrderKind.Forecast) {
      await handleUpdate();
    }
  };

  const handleUpdate = async () => {
    if (!orderDetails || orderKind !== OrderKind.Forecast) return;

    const updatedItems = Object.entries(itemQuantities).map(([itemId, quantity]) => {
      const sku = addedItems?.find(x => x.item.id.toString() === itemId)?.item.sku;
      const predictedQuantity = predictedDemands.find(x => x.itemNumber === sku)?.predictedDemandItems[0].quantity;
      return {
        id: parseInt(itemId),
        quantity: quantity,
        predictedQuantity,
      };
    });

    const updatedOrder = { id: orderDetails.id, items: updatedItems };

    try {
      setLoading(true);
      const result = await client.updateOrder(selectedStore?.storeNumber, updatedOrder);
      if (result.success) {
        handleSnackbarOpen(t('orderCreatedSuccessfully'), 'success');
      }
      else {
        setShowQuantityWarningDialog(true);
        setWarningMessage(result.message);
        fixQuantities();
      }
    } catch (error) {
      handleSnackbarOpen(t('saveError') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleClearFilters = async () => {
    setLoading(true);
    await fetchItems({
      item: '',
      orderType: orderType,
      storeNumber: selectedStore?.storeNumber,
      categoryId: -1,
      supplier: supplier,
      pageNumber: 0,
      pageSize: 10,
      sort: 'Name',
      direction: 'asc',
    });
    setPage(0);
    setPageSize(10);
    setProductSearch('');
    setCategory(-1);
    setLoading(false);
  };

  const getFilterOptions = (resetPage: boolean) => {
    const filterOptions: ItemsFilterOptions = {
      storeNumber: selectedStore?.storeNumber,
      item: productSearch,
      orderType: orderType,
      categoryId: category,
      supplier: supplier,
      pageNumber: resetPage ? 0 : page,
      pageSize: pageSize,
      sort: orderBy,
      direction: order,
    };
    return filterOptions;
  };

  const handleAddItem = (item: Item, quantity: number) => {
    const newItem = { item, quantity: Number(quantity), isNew: true, maxQuantity: item.maxQuantity, minQuantity: item.minQuantity };
    setAddedItems([...addedItems, newItem]);
    setItemQuantities(prevQuantities => ({ ...prevQuantities, [item.id]: quantity }));
  };

  const handleRemoveItem = (itemId: number) => {
    setAddedItems(addedItems.filter(addedItem => addedItem.item.id !== itemId));
    setItemQuantities(prevQuantities => {
      const newQuantities = { ...prevQuantities };
      delete newQuantities[itemId];
      return newQuantities;
    });
  };

  const handleQuantityChange = (itemId: number, quantity: number) => {
    setItemQuantities(prevQuantities => ({ ...prevQuantities, [itemId]: quantity }));
  };

  const isItemInOrder = (itemId: number) => {
    return addedItems.some(orderItem => orderItem.item.id === itemId);
  };

  const disableUnavailableDates = (date: Dayjs) => {
    const today = dayjs();
    const dayjsDate = dayjs(date);

    if (isAutoShipOrder(orderType)) {
      return true;
    }

    if (orderKind === OrderKind.Emergency) {
      return dayjsDate.isBefore(today, 'day');
    }

    return !deliverySchedules.some((schedule) =>
      dayjsDate.isSame(dayjs(schedule.deliveryDate, 'DD-MM-YYYY'), 'day'),
    );
  };

  const handleDeliveryDateChange = async (newDate: Dayjs | null) => {
    setLoading(true);

    if (orderKind === OrderKind.Emergency) {
      setCutOffDate(dayjs());
    } else {
      const schedule = deliverySchedules.find((ds) =>
        dayjs(ds.deliveryDate, 'DD-MM-YYYY').isSame(dayjs(newDate), 'day'),
      );
      setCutOffDate(dayjs(schedule?.cutOffDate, 'DD-MM-YYYY'));
    }

    setDeliveryDate(newDate);

    if (orderKind === OrderKind.Forecast) {
      const formattedDate = dayjs(newDate).format('DD-MM-YYYY');
      const orderResponse = await client.getOrderByDeliveryDate(selectedStore?.storeNumber, orderType, formattedDate, supplier);

      if (orderResponse?.data) {
        setOrderDetails(orderResponse.data);

        const fetchedAddedItems = orderResponse.data.items.map(item => ({
          item: {
            id: item.itemId,
            sku: item.sku,
            name: item.name,
            categoryId: item.categoryId,
            category: item.category,
            stockOnHand: item.stockOnHand,
            stockOnOrder: item.stockOnOrder,
            price: item.price,
            primaryUom: item.primaryUom,
            orderableUom: item.orderableUom,
            barcode: '',
            barcodeUom: '',
            imageId: item.imageId,
            thumbnailUrl: item.thumbnailUrl,

          },
          quantity: item.quantity,
          maxQuantity: item.maxQuantity,
          minQuantity: item.minQuantity,
          isNew: false,
        }));

        setAddedItems(fetchedAddedItems);

        const newItemQuantities = orderResponse.data.items.reduce((acc, item) => ({
          ...acc,
          [item.itemId]: item.quantity,
        }), {});

        setItemQuantities(newItemQuantities);
      }
    }

    setLoading(false);
  };

  const handleOrderKindChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEmergencyOrder = event.target.checked;
    if (isEmergencyOrder) {
      setOrderKind(OrderKind.Emergency);
      setCutOffDate(dayjs());
      setAddedItems([]);
      setItemQuantities({});
      setOrderDetails(null);
    } else {
      setOrderKind(OrderKind.Forecast);
      setDeliveryDate(null);
      setCutOffDate(null);
      setAddedItems([]);
      setItemQuantities({});
      setOrderDetails(null);
    }
  };

  const handleOrderTypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOrderType = Number(event.target.value);
    setOrderType(newOrderType);
    setLoading(true);
    setAddedItems([]);
    setItemQuantities({});
    setDeliveryDate(null);
    setCutOffDate(null);
    setOrderDetails(null);
    setPage(0);
    setPageSize(10);
    setProductSearch('');
    setCategory(-1);
    const supplier = suppliers && suppliers.length > 0 && newOrderType === OrderTypeEnum.DirectStoreDelivery ? suppliers[0].number : '';
    setSupplier(supplier);
    await fetchCategories(newOrderType, supplier);
    await fetchItems({
      item: '',
      orderType: newOrderType,
      storeNumber: selectedStore?.storeNumber,
      categoryId: -1,
      supplier: supplier,
      pageNumber: 0,
      pageSize: 15,
      sort: 'Name',
      direction: 'asc',
    });
    setLoading(false);
    await fetchDeliverySchedules(newOrderType, supplier);
  };

  const handleSupplierChange = async (number: string) => {
    setSupplier(number);
    setLoading(true);
    await fetchCategories(orderType, number);
    await fetchItems({
      item: productSearch,
      orderType: orderType,
      storeNumber: selectedStore?.storeNumber,
      categoryId: -1,
      supplier: number,
      pageNumber: page,
      pageSize: pageSize,
      sort: 'Name',
      direction: 'asc',
    });
    await fetchDeliverySchedules(orderType, number);
    setLoading(false);
  };

  const autocompleteOptions = [{ id: -1, name: t('all'), parentName: '' }, ...(Array.isArray(categories) ? categories : [])];

  const canSaveOrder = (): boolean => {
    return !loading && deliveryDate !== null && cutOffDate !== null && ((!orderDetails) || (orderDetails && orderDetails?.status == OrderStatus.Editable));
  };

  const getPageTitle = (): string => {
    if (orderDetails) {
      return `${t('order')}: ${orderDetails.id}`;
    } else {
      return orderKind === OrderKind.Forecast ? t('selectDeliveryDateToBegin') : t('newOrder');
    }
  };

  const handleExitAndSave = async () => {
    if (canSaveOrder()) {
      await handleSave();
    }
    navigate('/store/orders-history');
  };

  const handleSnackbarOpen = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event: unknown) => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleShowPredictedQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowPredictedQuantity(event.target.checked);
  };

  const matchPredictedQuantities = (resetMatchedPages = false) => {
    setItemQuantities(matchQuantities);

    if (!isMatchPredictedQuantitiesActive) {
      setIsMatchPredictedQuantitiesActive(true);
    }

    if (resetMatchedPages) {
      setMatchedPredictionPages([page]);
    }
  };

  const matchQuantities = (oldQuantities: Record<number, number>) => {
    const newQuantities = JSON.parse(JSON.stringify(oldQuantities)) as Record<number, number>;
    const allItems = Array.from(new Set([...items, ...addedItems.map(x => x.item)]));
    allItems.forEach((item) => {
      const predictedItem = predictedDemands.find(p => p.itemNumber === item?.sku);
      const predictedDemandItem = predictedItem?.predictedDemandItems?.at(0);

      if (!predictedDemandItem?.hasPrediction) {
        return;
      }

      newQuantities[item.id] = predictedDemandItem.quantity;
    });

    return newQuantities;
  };

  const cutOffDateFormat = () => {
    const now = dayjs();
    const formattedCutOffDate = cutOffDate?.format('YYYY-MM-DD');

    const cutOffMoment = dayjs(`${formattedCutOffDate}T${orderDetails?.cutOffTime ?? ''}`);
    if (now.isAfter(cutOffMoment)) {
      return t('cutOffHasPassed');
    }

    const diffMilliseconds = cutOffMoment.diff(now);

    const diffSeconds = Math.floor(diffMilliseconds / 1000);
    const days = Math.floor(diffSeconds / (3600 * 24));
    const hours = Math.floor((diffSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((diffSeconds % 3600) / 60);

    return `${days} ${t('days')}, ${hours} ${t('hours')}, ${minutes} ${t('minutes')}`;
  };

  const getOptionLabel = (option: { parentName?: string; name: string }): string => {
    const parentName = option.parentName;
    const categoryName = option.name;

    const translatedParent = parentName && isOrderCategoryParent(parentName)
      ? t(OrdersCategoriesParentsMap[parentName as keyof typeof OrdersCategoriesParentsMap])
      : parentName;

    const translatedCategory = isOrderCategory(categoryName)
      ? t(OrderCategoriesTranslationMap[categoryName as keyof typeof OrderCategoriesTranslationMap])
      : categoryName;

    if (parentName) {
      return translatedParent
        ? `${translatedParent} > ${translatedCategory}`
        : `${parentName} > ${translatedCategory}`;
    } else {
      return translatedCategory;
    }
  };

  const quantityError = (item: AddedItem | Item, currentValue: number): string | null => {
    const quantity = Number(currentValue);
    if ((item.maxQuantity != null) && quantity > item.maxQuantity) {
      return `${t('maxQuantityAllowedIs')}: ${item.maxQuantity}`;
    }

    if ((item.minQuantity != null) && quantity < item.minQuantity)
      return `${t('minQuantityAllowedIs')}: ${item.minQuantity}`;

    return null;
  };

  const isQuantityError = (item: AddedItem | Item, currentValue: number): boolean => {
    return quantityError(item, currentValue) != null;
  };

  const anyErrors = () => {
    return addedItems.some(orderItem => isQuantityError(orderItem, itemQuantities[orderItem.item.id]));
  };

  const fixQuantities = () => {
    //shallow copy for state mang purposes
    const itemQuantities2 = { ...itemQuantities };
    for (const key in itemQuantities2) {
      const value = itemQuantities2[key];
      const maxQuant = orderDetails?.items.find(x => x.itemId.toString() == key)?.maxQuantity;
      const minQuant = orderDetails?.items.find(x => x.itemId.toString() == key)?.minQuantity;

      if (maxQuant && value > maxQuant)
        itemQuantities2[key] = maxQuant;
      if (minQuant && value < minQuant)
        itemQuantities2[key] = minQuant;
    }
    setItemQuantities(itemQuantities2);
  };

  return (
    <PageArea>
      <Grid item
        mb={10}
        sx={{ textAlign: 'left' }}>
        <BackButton
          handleClick={handleExitAndSave}
          title={t('exitAndSave')}
          isLink={false}
        />
      </Grid>
      <Grid container>
        <Grid item
          container
          justifyContent={'space-between'}
          md={12}>
          <Grid item
            xs={8}
            md={10}>
            <PageTitle>
              {getPageTitle()}
            </PageTitle>
          </Grid>
          <Grid item
            xs={4}
            md={2}>
            <Button
              variant="primary"
              disabled={!canSaveOrder() || anyErrors()}
              size="lg"
              fullWidth
              startIcon={<SaveIcon />}
              onClick={() => handleSave()}>
              {t('save')}
            </Button>
          </Grid>
          {orderDetails && <Grid item
            xs={12}
            md={5}> {<Typography
              variant='textMD'>{t('timeToOrderCutoff')}  <strong>{cutOffDateFormat()}</strong></Typography>}
          </Grid>}
        </Grid>
        <Grid item
          container
          mt={5}
          sm={12}
          sx={{
            pt: '20px',
            border: '1px solid',
            borderColor: theme.palette.custom.gray[300],
          }}>
          <Grid item
            container
            alignItems={'center'}
            xs={12}>
            <Grid item
              xl={4}
              md={isMobile ? 12 : 7}
              xs={isMobile ? 12 : 6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 0 }} >
              <Box>
                <DatePickerLocalized
                  label=''
                  useCalendar={true}
                  valueForDateCalendar={deliveryDate}
                  onChangeDateCalendar={handleDeliveryDateChange}
                  shouldDisableDateCalendar={disableUnavailableDates}
                  disabled={loading}
                  disableHighlightToday={true}
                />
              </Box>
            </Grid>
            <Grid item
              container
              alignItems={'center'}
              xl={8}
              md={isMobile ? 12 : 5}
              xs={isMobile ? 12 : 6}>
              <Grid item
                xl={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && !isMobile ? 4 : 6}
                md={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && !isMobile ? 6 : 12}
                xs={12}
                p={3}>
                <TextField
                  fullWidth
                  autoComplete='off'
                  size='small'
                  label={t('orderType')}
                  disabled={loading}
                  value={orderType}
                  required
                  onChange={handleOrderTypeChange}
                  select
                >
                  {orderTypes.map((type) => (
                    <MenuItem key={type.id}
                      value={type.id}>
                      {t(OrderTypeTranslationMap[type.name as keyof typeof OrderTypeTranslationMap]) || type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {orderType === OrderTypeEnum.DirectStoreDelivery &&
                <Grid item
                  xl={4}
                  md={!isMobile ? 6 : 12}
                  xs={12}
                  p={3}>
                  <TextField
                    fullWidth
                    size='small'
                    autoComplete='off'
                    label={t('supplier')}
                    disabled={loading}
                    value={supplier}
                    required
                    onChange={(e) => handleSupplierChange(e.target.value)}
                    select
                  >
                    {suppliers.map((type) => (
                      <MenuItem key={type.id}
                        value={type.number}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              }
              <Grid item
                xl={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery ? 4 : 6}
                md={12}
                p={3}>
                <Typography component="div">
                  <Grid component="label"
                    container
                    alignItems="center"
                    spacing={1}>
                    <Grid item>{t('emergencyOrder')}</Grid>
                    <Grid item>
                      <Switch
                        disabled={loading}
                        checked={orderKind === OrderKind.Emergency}
                        onChange={handleOrderKindChange}
                        name="orderKindSwitch"
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item
                md={6}
                xs={12}
                p={3}>
                <DateField
                  label={t('deliveryDate')}
                  disablePast={true}
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  readOnly
                  value={deliveryDate}
                />
              </Grid>
              <Grid item
                md={6}
                xs={12}
                p={3}>
                <DateField
                  label={t('cutOffDate')}
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  readOnly
                  value={cutOffDate}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item
            xs={12}
            mt={5}
            mb={5}>
            <Divider />
          </Grid>
          <Grid item
            container
            xs={12}
            spacing={5}>
            <Grid item
              container
              xs={12}>
              <Grid item
                lg={3}
                xs={12}
                p={5}>
                <TextField
                  fullWidth
                  size='small'
                  placeholder={t('searchByItemNameOrSKU')}
                  disabled={loading}
                  value={productSearch}
                  onChange={(e) => setProductSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon><SearchSm /></SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item
                lg={3}
                xs={12}
                p={5}>
                <Autocomplete
                  size='small'
                  disabled={loading}
                  options={autocompleteOptions}
                  getOptionLabel={getOptionLabel}
                  value={categories.find(cat => cat.id === category) ?? autocompleteOptions[0]}
                  onChange={(event, newValue) => {
                    setCategory(newValue ? newValue.id : -1);
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={t('category')}
                      placeholder={t('choose')} />
                  )}
                />
              </Grid>
              <Grid item
                lg={3}
                xs={12}
                p={5}>
                <Button fullWidth
                  variant="secondary"
                  disabled={loading}
                  size="lg"
                  onClick={handleClearFilters}>
                  {t('clearFilters')}
                </Button>
              </Grid>
              <Grid item
                lg={3}
                xs={12}
                p={5}>
                <Button fullWidth
                  variant="primary"
                  disabled={loading}
                  size="lg"
                  startIcon={<SearchSm />}
                  onClick={handleSearch}>
                  {t('search')}
                </Button>
              </Grid>
              {
                isPodEnabled &&
                <>
                  <Grid item
                    xs={12}
                    lg={3}
                    p={5}>
                    <FormControlLabel control={
                      <Switch checked={showPredictedQuantity}
                        onChange={handleShowPredictedQuantityChange}/>
                    }
                    label={t('showPredictedQuantities')} />
                  </Grid>
                  {
                    showPredictedQuantity && 
                    <Grid item
                      xs={12}
                      lg={3}
                      p={5}>
                      <Button fullWidth
                        variant="primary"
                        disabled={loading}
                        size="lg"
                        startIcon={<MagicWand01 />}
                        onClick={() => matchPredictedQuantities(true)}>
                        {t('matchPredictedQuantities')}
                      </Button>
                    </Grid>
                  }
                </>
              }
            </Grid>
            <Grid item
              lg={6}
              xs={12}>
              <Typography px={4}
                variant="displayXS">{t('availableItems')}</Typography>
              {loading ? <LoadingWheel /> : <TableContainer component={'div'}>
                <Table
                  size="small"
                  aria-label="a table"
                >
                  <TableHead
                    sx={{
                      paddingTop: '10px',
                      backgroundColor: theme.palette.custom.gray[200],
                    }}>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'SKU'}
                          direction={order}
                          onClick={() => handleSort('SKU')}>
                          {t('sku')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'Name'}
                          direction={order}
                          onClick={() => handleSort('Name')}>
                          {t('name')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        {t('uom')}
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="center">
                        {t('quantity')}
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="center">
                        {t('actions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item) => {
                      const quantity = itemQuantities[item.id] || 1;
                      const predictedItem = predictedDemands.find(x => x.itemNumber === item.sku);
                      const predictedDemandItem = predictedItem?.predictedDemandItems?.at(0);
                      const showPrediction = showPredictedQuantity && predictedDemandItem?.hasPrediction;
                      const predictedQuantity = predictedDemandItem?.quantity;
                      const borderColor = showPrediction && predictedQuantity === quantity ? theme.palette.success[400] : '';
                      const maxOrderQuantity = predictedItem?.predictionInfo?.maxOrderQuantity;
                      const safetyStock = predictedItem?.predictionInfo?.safetyStock;
                      const predictedDemandQuantity = predictedDemandItem?.predictedDemandQuantity;

                      return (
                        <TableRow key={item.id}>
                          <TableCell>{item.sku}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.orderableUom}</TableCell>
                          <TableCell>
                            <Box className="product-quantity-container"
                              sx={{ display: 'flex', position: 'relative', maxWidth: 100 }}>
                              <TextField
                                label={showPrediction ? t('predictedQuantity', { value: predictedQuantity }) : null}
                                size="small"
                                type="number"
                                autoComplete='off'
                                disabled={isItemInOrder(item.id)}
                                value={quantity}
                                onChange={(e) => handleQuantityChange(item.id, Math.abs(Number(e.target.value || '0')))}
                                sx={{
                                  maxWidth: 100,
                                  width: '100%',
                                  input: {
                                    textAlign: 'center',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: borderColor,
                                    },
                                  },

                                  '& .MuiInputLabel-root': {
                                    color: borderColor,
                                  },
                                }}
                              ></TextField> 
                              {
                                showPrediction &&
                                <Tooltip title={
                                  <span>
                                    {
                                      predictedDemandQuantity != null && (
                                        <>
                                          {t('predictedDemandQuantity', { value: predictedDemandQuantity, uom: item.primaryUom })}
                                          <br />
                                        </>
                                      )
                                    }
                                    {
                                      maxOrderQuantity != null && (
                                        <>
                                          {t('maxOrderQuantity', { value: maxOrderQuantity, uom: item.primaryUom })}
                                          <br />
                                        </>
                                      )
                                    }
                                    {safetyStock != null && t('safetyStock', { value: safetyStock, uom: item.primaryUom })}
                                  </span>
                                }>
                                  <IconButton onClick={() => handleQuantityChange(item.id, Number(predictedQuantity))}>
                                    <MagicWand01
                                      color={predictedQuantity === quantity ? theme.palette.success[700] : theme.palette.custom.gray[400] }/>
                                  </IconButton>
                                </Tooltip>
                              }
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleAddItem(item, quantity)}
                              variant="primary"
                              disabled={isItemInOrder(item.id) || !canSaveOrder()}
                              size="md"
                              startIcon={isItemInOrder(item.id) ? <DoneIcon /> : <Plus />}>
                              {isItemInOrder(item.id) ? t('added') : t('add')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!loading && items.length === 0 ? (
                  <Box
                    sx={{
                      py: '20px',
                      px: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="textLG"
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {t('noItems')}
                    </Typography>
                  </Box>
                ) : (
                  <CustomPagination
                    page={page}
                    setPage={setPage}
                    maxPages={totalPages}
                    breakpointForChangeDisplay={120000}
                  />
                )}
              </TableContainer>
              }
            </Grid>
            <Grid item
              lg={6}
              xs={12}>
              <Typography px={4}
                variant="displayXS">{t('addedItems')}</Typography>
              <TableContainer component={'div'}>
                <Table
                  size="small"
                  aria-label="a table"
                >
                  <TableHead
                    sx={{
                      paddingTop: '10px',
                      backgroundColor: theme.palette.custom.gray[200],
                    }}>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        {t('sku')}
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        {t('name')}
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        {t('uom')}
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="center">
                        {t('quantity')}
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="center">
                        {t('actions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addedItems.map((orderItem) => {
                      const quantity = itemQuantities[orderItem.item.id] || 1;
                      const predictedItem = predictedDemands.find(x => x.itemNumber === orderItem.item.sku);
                      const predictedDemandItem = predictedItem?.predictedDemandItems?.at(0);
                      const showPrediction = showPredictedQuantity && predictedDemandItem?.hasPrediction;
                      const predictedQuantity = predictedDemandItem?.quantity;
                      const borderColor = showPrediction && predictedQuantity === quantity ? theme.palette.success[400] : '';
                      const maxOrderQuantity = predictedItem?.predictionInfo?.maxOrderQuantity;
                      const safetyStock = predictedItem?.predictionInfo?.safetyStock;
                      const predictedDemandQuantity = predictedDemandItem?.predictedDemandQuantity;

                      return (
                        <TableRow key={orderItem.item.id}>
                          <TableCell>{orderItem.item.sku}</TableCell>
                          <TableCell>{orderItem.item.name}</TableCell>
                          <TableCell>{orderItem.item.orderableUom}</TableCell>
                          <TableCell sx={{ overflow: 'hidden' }}>
                            <Box className="product-quantity-container"
                              sx={{ display: 'flex', position: 'relative', maxWidth: 100 }}>
                              <TextField
                                label={showPrediction ? t('predictedQuantity', { value: predictedQuantity }) : null}
                                error={isQuantityError(orderItem, itemQuantities[orderItem.item.id])}
                                helperText={quantityError(orderItem, itemQuantities[orderItem.item.id])}
                                size="small"
                                type="number"
                                autoComplete='off'
                                disabled={!canSaveOrder()}
                                value={quantity}
                                onChange={(e) => handleQuantityChange(orderItem.item.id, Math.abs(Number(e.target.value || '0')))}
                                sx={{
                                  maxWidth: 100,
                                  width: '100%',
                                  input: {
                                    textAlign: 'center',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: borderColor,
                                    },
                                  },

                                  '& .MuiInputLabel-root': {
                                    color: borderColor,
                                  },
                                }}
                              ></TextField>
                              {
                                showPrediction &&
                                <Tooltip title={
                                  <span>
                                    {
                                      predictedDemandQuantity != null && (
                                        <>
                                          {t('predictedDemandQuantity', { value: predictedDemandQuantity, uom: orderItem.item.primaryUom })}
                                          <br />
                                        </>
                                      )
                                    }
                                    {
                                      maxOrderQuantity != null && (
                                        <>
                                          {t('maxOrderQuantity', { value: maxOrderQuantity, uom: orderItem.item.primaryUom })}
                                          <br />
                                        </>
                                      )
                                    }
                                    {safetyStock != null && t('safetyStock', { value: safetyStock, uom: orderItem.item.primaryUom })}
                                  </span>
                                }>
                                  <IconButton onClick={() => handleQuantityChange(orderItem.item.id, Number(predictedQuantity))}>
                                    <MagicWand01
                                      color={predictedQuantity === quantity ? theme.palette.success[700] : theme.palette.custom.gray[400] }/>
                                  </IconButton>
                                </Tooltip>
                              }
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button
                              fullWidth
                              variant="bad"
                              size="md"
                              disabled={!canSaveOrder()}
                              startIcon={<DeleteIcon />}
                              onClick={() => handleRemoveItem(orderItem.item.id)}>
                              {t('remove')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {addedItems.length === 0 ? (
                  <Box
                    sx={{
                      py: '20px',
                      px: '16px',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="textLG"
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {t('noItemsAdded')}
                    </Typography>
                  </Box>
                ) : ('')}
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={snackbar.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <QuantityWarningDialog
        setShowQuantityWarningDialog={setShowQuantityWarningDialog}
        message={warningMessage}
        showDialog={showQuantityWarningDialog} />
    </PageArea>
  );
};

export default QuickOrderPage;
